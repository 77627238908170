import React from 'react';
import PropTypes from 'prop-types';

import { getPersistentColorForResourceId } from 'dpl/shared/utils/styles';
import SmartImage from 'dpl/components/SmartImage';
import Icon from 'dpl/common/components/Icon';

export default function UserProfilePhoto({
  user,
  diameter,
  fontSize,
  forceColor,
  smartImageProps,
  profilePhotoBgColor
}) {
  if (!user) {
    return (
      <Icon
        name="avatar"
        width={diameter}
        height={diameter}
        className="purple"
      />
    );
  }

  if (user.profile_photo_url) {
    return (
      <SmartImage
        Tag="div"
        src={user.profile_photo_url}
        width={diameter}
        height={diameter}
        className={`bg-center cover h-100 w-100 br-100 ${profilePhotoBgColor}`}
        {...smartImageProps}
      />
    );
  }

  return (
    <span
      style={{
        backgroundColor:
          forceColor || (user.id && getPersistentColorForResourceId(user.id)),
        width: diameter,
        height: diameter,
        minWidth: diameter,
        fontSize
      }}
      className="h-100 w-100 br-100 dib ttu white flex inline-flex align-items-center justify-center"
    >
      <span className="UserProfilePhoto__initials fw-medium lh-1">
        {user.initials}
      </span>
    </span>
  );
}

UserProfilePhoto.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profile_photo_url: PropTypes.string,
    initials: PropTypes.string
  }),
  diameter: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  forceColor: PropTypes.string,
  profilePhotoBgColor: PropTypes.string,
  smartImageProps: PropTypes.shape({
    crop: PropTypes.bool,
    lazy: PropTypes.bool,
    bgColor: PropTypes.string,
    loadingClass: PropTypes.string
  })
};

UserProfilePhoto.defaultProps = {
  fontSize: '15px',
  forceColor: null,
  user: null,
  profilePhotoBgColor: '',
  smartImageProps: {
    crop: false,
    lazy: false,
    bgColor: '',
    loadingClass: ''
  }
};
