import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { toggleOverlayAppContent } from 'dpl/shared/utils';
import {
  BUYER_BASE_PATH,
  LEARNING_CENTER_BREED_TYPES
} from 'dpl/shared/constants/urls';
import { ORDERED_BREED_TYPES } from 'dpl/components/BreedSearch/util';
import Search from 'dpl/components/Search';
import ShelterResultsListContainer from 'dpl/search/containers/ShelterResultsListContainer';
import BreedSearch from 'dpl/components/BreedSearch';
import Icon from 'dpl/common/components/Icon';
import FancyDropdown, {
  FancyDropdownMenu,
  FancyDropdownToggle,
  FancyDropdownMenuItem
} from 'dpl/components/FancyDropdown';

import { SEARCH_TYPES, ORDERED_SEARCH_TYPES } from './util';
import HeaderSearchBreedTypeTitle from './HeaderSearchBreedTypeTitle';

function HeaderSearchTypeButton({
  type,
  isSelected,
  onClick,
  children,
  nested
}) {
  return (
    <div className="HeaderSearchTypeButton w-100">
      <button
        type="button"
        className={classnames(
          'w-100 pv3 pr6 nowrap flex items-center fw-medium',
          {
            pl2: !nested,
            pl6: nested
          }
        )}
        onClick={() => onClick(type)}
      >
        <span className={classnames('mr1', { 'o-0': !isSelected })}>
          <Icon width="20px" height="20px" name="checkmark" />
        </span>
        {children || type}
      </button>
    </div>
  );
}

HeaderSearchTypeButton.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  children: PropTypes.node,
  nested: PropTypes.bool
};

HeaderSearchTypeButton.defaultProps = {
  children: null,
  nested: false
};

export default class HeaderSearch extends Component {
  static propTypes = {
    initialQuery: PropTypes.string
  };

  static defaultProps = {
    initialQuery: ''
  };

  state = {
    isOpen: false,
    isSearchDropdownOpen: window.__DPL_BOT_UA,
    searchType: window.location.pathname.startsWith('/shelters')
      ? SEARCH_TYPES.SHELTERS
      : ORDERED_SEARCH_TYPES[0]
  };

  handleDropdownChange = isOpen => {
    this.setState({ isOpen });
  };

  handleSearchTypeChange = searchType => {
    this.setState({
      searchType,
      isOpen: false
    });
  };

  handleSearchDropdownChange = isOpen => {
    this.setState({
      isSearchDropdownOpen: isOpen
    });
  };

  openSearchDropdown = () => {
    this.setState({
      isSearchDropdownOpen: true
    });
  };

  componentDidUpdate() {
    const { isSearchDropdownOpen, isOpen } = this.state;
    toggleOverlayAppContent(isSearchDropdownOpen || isOpen);
  }

  componentWillUnmount() {
    toggleOverlayAppContent(false);
  }

  render() {
    // Do not render on buyer account pages
    if (window.location.pathname.startsWith(BUYER_BASE_PATH)) {
      return null;
    }

    const { isOpen, searchType, isSearchDropdownOpen } = this.state;
    const { initialQuery } = this.props;
    const isBreedSearch =
      ORDERED_BREED_TYPES.includes(searchType) ||
      searchType === SEARCH_TYPES.BREED_PROFILES;

    return (
      <div className="HeaderSearch">
        <div className="flex ba b--light-gray br1">
          <FancyDropdown
            isOpen={isOpen}
            onChange={this.handleDropdownChange}
            allowPropagationOnClose
          >
            <FancyDropdownToggle>
              <p className="HeaderSearch__toggle pl4 pl6-lg pr2 pr4-lg pv3 pv4-lg link--primary flex items-center fw-medium">
                <span className="truncate">
                  {isBreedSearch ? (
                    <HeaderSearchBreedTypeTitle breedType={searchType} />
                  ) : (
                    searchType
                  )}
                </span>
                <Icon
                  className="ml2"
                  name={`fat-arrow-${isOpen ? 'up' : 'down'}`}
                />
              </p>
            </FancyDropdownToggle>

            <FancyDropdownMenu
              className="br2 box-shadow-2 bg-white mt2 ph4 ba b--light-gray w-auto"
              contentClassName="pt2"
            >
              <div className="mb2">
                <FancyDropdownMenuItem itemKey="all-breeders">
                  <HeaderSearchTypeButton
                    type={SEARCH_TYPES.ALL}
                    isSelected={searchType === SEARCH_TYPES.ALL}
                    onClick={this.handleSearchTypeChange}
                  >
                    <HeaderSearchBreedTypeTitle breedType={SEARCH_TYPES.ALL} />
                  </HeaderSearchTypeButton>
                </FancyDropdownMenuItem>
                <FancyDropdownMenuItem itemKey="purebred-breeders">
                  <HeaderSearchTypeButton
                    nested
                    type={SEARCH_TYPES.PUREBRED}
                    isSelected={searchType === SEARCH_TYPES.PUREBRED}
                    onClick={this.handleSearchTypeChange}
                  >
                    <HeaderSearchBreedTypeTitle
                      breedType={SEARCH_TYPES.PUREBRED}
                    />
                  </HeaderSearchTypeButton>
                </FancyDropdownMenuItem>
                <FancyDropdownMenuItem itemKey="crossbred-breeders">
                  <HeaderSearchTypeButton
                    nested
                    type={SEARCH_TYPES.CROSSBRED}
                    isSelected={searchType === SEARCH_TYPES.CROSSBRED}
                    onClick={this.handleSearchTypeChange}
                  >
                    <HeaderSearchBreedTypeTitle
                      breedType={SEARCH_TYPES.CROSSBRED}
                    />
                  </HeaderSearchTypeButton>
                </FancyDropdownMenuItem>
              </div>
              <div className="bt b--light-gray pv2">
                <FancyDropdownMenuItem itemKey="shelters">
                  <HeaderSearchTypeButton
                    type={SEARCH_TYPES.SHELTERS}
                    isSelected={searchType === SEARCH_TYPES.SHELTERS}
                    onClick={this.handleSearchTypeChange}
                  />
                </FancyDropdownMenuItem>
              </div>
              <div className="bt b--light-gray pv2">
                <FancyDropdownMenuItem itemKey="breed_profiles">
                  <HeaderSearchTypeButton
                    type={SEARCH_TYPES.BREED_PROFILES}
                    isSelected={searchType === SEARCH_TYPES.BREED_PROFILES}
                    onClick={this.handleSearchTypeChange}
                  />
                </FancyDropdownMenuItem>
              </div>

              <p className="bg-near-white pv2 ph4 nh4 f2 black-50 fw-medium">
                *
                <a
                  href={LEARNING_CENTER_BREED_TYPES}
                  className="underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
                &nbsp;about purebreds &amp; crossbreds
              </p>
            </FancyDropdownMenu>
          </FancyDropdown>

          <span className="br mv2 b--light-gray" />

          <div className="relative flex-auto flex items-center">
            <div className="flex-auto">
              {isBreedSearch && (
                <BreedSearch
                  placeholder="Enter a breed, e.g. “Havanese”"
                  className="w-100 pl4 pl6-lg pr14 pv3 pv4-lg b--none br1 fw-medium"
                  onToggle={this.handleSearchDropdownChange}
                  query={initialQuery}
                  isOpen={isSearchDropdownOpen}
                  breedType={searchType}
                />
              )}

              {searchType === SEARCH_TYPES.SHELTERS && (
                <Search
                  ResultsComponent={ShelterResultsListContainer}
                  inputClassName="w-100 pl4 pl6-lg pr14 pv3 pv4-lg b--none br1 fw-medium"
                  placeholder="Enter your zip code or city"
                  onToggle={this.handleSearchDropdownChange}
                  isOpen={isSearchDropdownOpen}
                />
              )}
            </div>

            <button
              type="button"
              onClick={this.openSearchDropdown}
              className="HeaderSearch__iconContainer f0 absolute right-0 mr4 br-100 overflow-hidden"
            >
              <Icon className="purple transform-center-all" name="magnifier" />
              <div className="hover-bg-purple transition absolute absolute--fill o-05" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
