import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { toggleOverlayAppContent } from 'dpl/shared/utils';
import withToggle from 'dpl/decorators/withToggle';
import UserProfilePhoto from 'dpl/components/UserProfilePhoto';
import FancyDropdown, {
  FancyDropdownToggle,
  FancyDropdownMenu,
  FancyDropdownMenuItem
} from 'dpl/components/FancyDropdown';
import Icon from 'dpl/common/components/Icon';
import UserLoginButtonContainer from 'dpl/UserAuth/containers/UserLoginButtonContainer';
import BreederSignupHeaderButtonContainer from 'dpl/containers/Header/BreederSignupHeaderButtonContainer';
import { JOIN_AS_SHELTER_PATH } from 'dpl/shared/constants/urls';

import BuyerSignupHeaderButton from '../BuyerSignupHeaderButton';

class UserHeaderLoggedOutDropdown extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired
  };

  handleDropdownChange = isOpen => {
    this.props.toggle(isOpen);
    toggleOverlayAppContent(isOpen);
  };

  componentWillUnmount() {
    toggleOverlayAppContent(false);
  }

  render() {
    const { isOpen } = this.props;

    return (
      <div className="UserHeaderLoggedOutDropdown">
        <FancyDropdown
          allowPropagationOnClose
          isOpen={isOpen}
          onChange={this.handleDropdownChange}
          menuPosition="right"
        >
          <FancyDropdownToggle>
            <span className="flex align-items-center purple">
              <span className="d-none d-xl-block mr3 fw-medium">Account</span>
              <span className="mr1">
                <UserProfilePhoto diameter="40px" />
              </span>
              <Icon name={`fat-arrow-${isOpen ? 'up' : 'down'}`} />
            </span>
          </FancyDropdownToggle>
          <FancyDropdownMenu className="UserHeaderLoggedOutDropdown__menu br2 box-shadow-2 bg-white mt2 ph2 pv2 ba b--light-gray w-auto">
            <FancyDropdownMenuItem itemKey="login">
              <UserLoginButtonContainer
                className="w-100 pv2 ph6"
                showSignupText={false}
              />
            </FancyDropdownMenuItem>
            <FancyDropdownMenuItem itemKey="breeder-signup">
              <BreederSignupHeaderButtonContainer className="db pv2 ph6" />
            </FancyDropdownMenuItem>
            <FancyDropdownMenuItem itemKey="shelter-signup">
              <a href={JOIN_AS_SHELTER_PATH} className="w-100 db pv2 ph6">
                Join as shelter
              </a>
            </FancyDropdownMenuItem>
            <FancyDropdownMenuItem itemKey="signup">
              <BuyerSignupHeaderButton className="db pv2 ph6" />
            </FancyDropdownMenuItem>
          </FancyDropdownMenu>
        </FancyDropdown>
      </div>
    );
  }
}

export default withToggle({ propName: 'isOpen' })(UserHeaderLoggedOutDropdown);
