import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {
  DESCRIPTIVE_BADGE_VARIANTS,
  DESCRIPTIVE_BADGE_SIZES
} from 'dpl/common/utils/constants';

const VARIANT_CLASSES = {
  [DESCRIPTIVE_BADGE_VARIANTS.PRIMARY]: 'b--purple purple',
  [DESCRIPTIVE_BADGE_VARIANTS.SUCCESS]: 'b--green green',
  [DESCRIPTIVE_BADGE_VARIANTS.WARNING]: 'b--gold gold',
  [DESCRIPTIVE_BADGE_VARIANTS.ERROR]: 'b--red red',
  [DESCRIPTIVE_BADGE_VARIANTS.DEFAULT]: 'b--light-gray black fw-medium',
  [DESCRIPTIVE_BADGE_VARIANTS.DISABLED]: 'b--light-gray black-50 fw-medium',
  [DESCRIPTIVE_BADGE_VARIANTS.REGULAR]: 'b--light-gray black',
  [DESCRIPTIVE_BADGE_VARIANTS.SECONDARY]: 'b--light-gray mid-gray fw-medium'
};

const SIZE_CLASSES = {
  [DESCRIPTIVE_BADGE_SIZES.LARGE]: 'f3',
  [DESCRIPTIVE_BADGE_SIZES.MEDIUM]: 'f2',
  [DESCRIPTIVE_BADGE_SIZES.SMALL]: 'f2',
  [DESCRIPTIVE_BADGE_SIZES.XSMALL]: 'f1'
};

export default function DescriptiveBadge({
  label,
  variant,
  size,
  borderRadiusClassName,
  className
}) {
  const Tag = typeof label === 'string' ? 'p' : 'div';

  return (
    <Tag
      className={classnames(
        `DescriptiveBadge DescriptiveBadge--${size} ba dib`,
        VARIANT_CLASSES[variant],
        SIZE_CLASSES[size],
        borderRadiusClassName,
        className
      )}
      data-test-id="DescriptiveBadge"
    >
      {label}
    </Tag>
  );
}

DescriptiveBadge.propTypes = {
  label: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(Object.values(DESCRIPTIVE_BADGE_VARIANTS)),
  size: PropTypes.oneOf(Object.values(DESCRIPTIVE_BADGE_SIZES)),
  borderRadiusClassName: PropTypes.string,
  className: PropTypes.string
};

DescriptiveBadge.defaultProps = {
  size: DESCRIPTIVE_BADGE_SIZES.MEDIUM,
  variant: DESCRIPTIVE_BADGE_VARIANTS.DEFAULT,
  borderRadiusClassName: 'br1',
  className: ''
};
