import React from 'react';
import PropTypes from 'prop-types';

import { ORDERED_SEARCH_TYPES } from 'dpl/shared/constants/shared';
import { MENU_TYPES } from 'dpl/decorators/withMobileMenu';
import Icon from 'dpl/common/components/Icon';
import GlobalMobileMenuCloseButtonContainer from 'dpl/containers/GlobalMobileMenu/GlobalMobileMenuCloseButtonContainer';

export default function GlobalMobileMenuSearchButton({
  openMobileMenuName,
  openSearchBreedersMenu,
  openSearchSheltersMenu,
  searchType
}) {
  const isOpen = openMobileMenuName === MENU_TYPES.SEARCH;

  return (
    <div className="GlobalMobileMenuSearchButton">
      <div className="dib f3">
        {!isOpen ? (
          <button
            type="button"
            onClick={
              searchType === ORDERED_SEARCH_TYPES.SHELTERS
                ? openSearchSheltersMenu
                : openSearchBreedersMenu
            }
            className="w-100 no-select pv2"
            aria-label="search breed"
          >
            <Icon
              className="HeaderSearch__icon"
              name="fetch-search"
              height="24px"
              width="24px"
            />
          </button>
        ) : (
          <GlobalMobileMenuCloseButtonContainer />
        )}
      </div>
    </div>
  );
}

GlobalMobileMenuSearchButton.propTypes = {
  openMobileMenuName: PropTypes.string,
  openSearchBreedersMenu: PropTypes.func.isRequired,
  openSearchSheltersMenu: PropTypes.func.isRequired,
  searchType: PropTypes.string
};

GlobalMobileMenuSearchButton.defaultProps = {
  openMobileMenuName: null,
  searchType: ORDERED_SEARCH_TYPES.BREEDERS
};
